<template>
  <!-- error page-->
  <div id="error-404-page">
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
          <p class="mb-2">
            Oops! 😖 The requested URL was not found on this server.
          </p>

          <b-button
            variant="info"
            class="mb-2 btn-sm-block"
            :to="{ path: '/' }"
          >
            Back to Dashboard
          </b-button>

          <!-- image -->
          <b-img fluid :src="imgUrl" alt="Error page" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BLink, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";

export default {
  name: "Error404",
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>